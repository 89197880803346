import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/page/list' },
    {
        path: '/page',
        component: () => import('../views/page.vue'),
        children: [
            {
                path: 'list',
                component: () => import('../views/list/list.vue')
            },
            {
                path: 'detail',
                meta: { showBack: true },
                component: () => import('../views/list/detail.vue')
            }
        ]
    },
    {
        path: '/login',
        component: () => import('../views/login.vue')
    },
    { path: '*', redirect: '/404' },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/404.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: '/admin',
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.path === '/login') {
        next()
        return
    }
    // 如果已经登录
    if (localStorage.getItem('login')) {
        next()
    } else {
        next({ path: '/login' })
    }
})
export default router
