import axios from 'axios'
import router from './router/index'
const service = axios.create({
    timeout: 60000,
    baseURL: process.env.VUE_APP_MODE === 'dev' ? '/api' : ''
})
console.log(process.env.VUE_APP_MODE)
service.all = axios.all

service.interceptors.request.use(config => {
    return config
}, (error) => Promise.reject(error))

service.interceptors.response.use(response => {
    const res = response.data
    if (res.code === '40003') {
        window.localStorage.setItem('login', '')
        document.cookie = 'MK=0;path=/;'
        router.replace('/login')
    }
    return res
}, (error) => {
    return Promise.reject(error)
})

export default service
